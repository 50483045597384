var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-component-modal',{attrs:{"modalTitle":_vm.$t('Components.BlockRules.ModalDeleteBlockRule.Main_Title'),"confirmButtonCssClass":"is-danger","buttonLabelConfirm":_vm.$t('Form.Control.Delete'),"isSaving":_vm.isDeleting,"isSavingSuccess":_vm.isDeletingSuccess,"savingSuccessMessage":_vm.$t('Components.BlockRules.ModalDeleteBlockRule.Message_SavingSuccess', {
      name: _vm.blockrule.Name,
    }),"isSavingError":_vm.isDeletingError,"savingErrorMessage":_vm.$t('Components.BlockRules.ModalDeleteBlockRule.Message_SavingError'),"disableSaveButton":!_vm.isDeleteConfirmed || _vm.isDeleting,"hideFooterButtons":_vm.isDeletingError || _vm.isDeletingSuccess,"onClickCancel":_vm.onClickCancel,"onClickSave":_vm.deleteBlockRule,"showModal":_vm.showModal},on:{"closeModal":_vm.onClickCancel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"has-margin-bottom is-size-5 has-text-centered",domProps:{"innerHTML":_vm._s(
        _vm.$t('Components.BlockRules.ModalDeleteBlockRule.Text_Delete', {
          name: _vm.blockrule.Name,
        })
      )}}),_c('div',{staticClass:"has-text-centered"},[_c('BaseCheckbox',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Components.BlockRules.ModalDeleteBlockRule.Text_YesImSure')},model:{value:(_vm.isDeleteConfirmed),callback:function ($$v) {_vm.isDeleteConfirmed=$$v},expression:"isDeleteConfirmed"}})],1)]},proxy:true}])},[_c('template',{slot:"modalTitle"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }