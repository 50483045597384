import axios from 'axios'
import store from '../store'

let getBlockRuleToken = null
let getBlockRulesToken = null

export default {
  methods: {
    async getLocationBlockRuleByBlockId(blockId) {
      if (getBlockRuleToken) {
        getBlockRuleToken.cancel()
      }
      getBlockRuleToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/block/${blockId}`,
        {
          cancelToken: getBlockRuleToken.token,

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationBlockRules(
      locationId,
      searchTerm,
      page,
      itemsPerPage,
      typeId = 0
    ) {
      if (getBlockRulesToken) {
        getBlockRulesToken.cancel()
      }
      getBlockRulesToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/block/rules/location/${locationId}`,
        {
          cancelToken: getBlockRulesToken.token,

          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            typeId: typeId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createBlockRule(blockrule) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/block`,
        JSON.stringify(blockrule),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteBlock(blockId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/block/${blockId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteBlockRule(blockruleId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/block/rule/${blockruleId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
