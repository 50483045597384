<template>
  <ui-component-modal
    :modalTitle="$t('Components.BlockRules.ModalDeleteBlockRule.Main_Title')"
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :savingSuccessMessage="
      $t('Components.BlockRules.ModalDeleteBlockRule.Message_SavingSuccess', {
        name: blockrule.Name,
      })
    "
    :isSavingError="isDeletingError"
    :savingErrorMessage="
      $t('Components.BlockRules.ModalDeleteBlockRule.Message_SavingError')
    "
    :disableSaveButton="!isDeleteConfirmed || isDeleting"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteBlockRule"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div
        class="has-margin-bottom is-size-5 has-text-centered"
        v-html="
          $t('Components.BlockRules.ModalDeleteBlockRule.Text_Delete', {
            name: blockrule.Name,
          })
        "
      ></div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="
            $t('Components.BlockRules.ModalDeleteBlockRule.Text_YesImSure')
          "
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import blockruleProvider from '@/providers/blockrule'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    blockrule: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeleting: false,
      isDeletingSuccess: false,
      isDeleteConfirmed: false,
      isDeletingError: false,
      pageState: this.$store.state.blockrulesPageState,
    }
  },

  computed: {},

  created() {},

  methods: {
    deleteBlockRule() {
      let self = this

      self.isDeleting = true

      blockruleProvider.methods
        .deleteBlockRule(self.blockrule.Id)
        .then((response) => {
          if (response.status === 204) {
            self.isDeletingSuccess = true

            self.$emit('blockruleDeleted', self.blockrule)

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .finally(() => {
          self.isDeleting = false
        })
    },
  },
}
</script>
